import axios from 'axios';


class FeaturesManager {
  store = {};
  initialized = false;

  load = async (storeUrl, applicationName, environment = undefined) => new Promise(async (resolve, reject) => {
    const url = environment
      ? `${storeUrl}/${applicationName}/${environment}`
      : `${storeUrl}/${applicationName}`;

    const response = await axios.get(url)
      .catch((err) => {
        console.log(err);
        if(err === undefined || err.response === undefined) {
          return reject(new Error('Failed to get response from store'))
        }
        if (err.response.status === 404) {
          return reject(new Error(`Failed to find feature flags for ${applicationName}.`));
        }
        else {
          return reject(new Error('Failed to retrieve features.'));
        }
      });

    if (response)
    {
      response.data.map((feature) => this.store[feature.featureName] = feature.state);
      this.initialized = true;
      resolve(this.store);
    }
    
    reject(new Error('Failed to initialize the store'));
  });

  /**
   * Gets the feature flag state
   * @param {string} featureFlagName - name of the feature flag
   * @returns a number for its state if it exists. Otherwise return 0 (true)
   */
  getFeatureFlagByName = (featureFlagName) => {
    if (!this.initialized) {
      throw new Error('The store has not been initialized. Please load the store.');
    }

    const featureFlag = this.store[featureFlagName];

    // If the feature cannot be found then assume theres no reason to hide it. Return true
    if (featureFlag === undefined) {
      return 0;
    }

    return featureFlag;
  }
};

const service = new FeaturesManager();

export default service;