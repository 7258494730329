import axios from 'axios';


class ConfigurationManager {
  store = {};
  initialized = false;

  load = async (storeUrl, applicationName, environment = undefined) => new Promise(async (resolve, reject) => {
    const url = environment
      ? `${storeUrl}/${applicationName}/${environment}`
      : `${storeUrl}/${applicationName}`;

    const response = await axios.get(url)
      .catch((err) => {
        if (err.response.status === 404) {
          return reject(new Error(`Failed to find a configuration for ${applicationName}`));
        }
        else {
          return reject(new Error('Failed to retrieve configuration'));
        }
      });

    this.store = response ? response.data : {};
    this.initialized = true;
    resolve(this.store);
  });

  getEnvironmentSettingByName = (settingName) => {
    if (!this.initialized) {
      throw new Error('The store has not been initialized. Please load the store.')
    }

    const setting = this.store[settingName];

    if (setting === undefined) {
      throw new Error(`Could not find a value for the setting: "${settingName}"`);
    }

    return setting;
  }

  // This function takes an array of strings
  // It will use each string in order to drill down to a particular setting
  getDrillDownSettingValue = (hierarchyNameArray) => {
    let setting = this.store;

    hierarchyNameArray.forEach(name => {
      setting = setting[name];

      if (setting === undefined) {
        throw new Error(`Could not find this setting with name "${name}`);
      }
    });

    return setting;
  }
};

const service = new ConfigurationManager();

export default service;