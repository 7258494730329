import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';

class CycApplicationInsightsReact {
  constructor() {
    this.reactPlugin = new ReactPlugin();
    this.initialized = false;
    this.defaults = {};
    const eventTypes = [  
      "trackException",
      "trackEvent",
      "trackPageView",
      "trackPageViewPerformance",
      "trackTrace",
      "trackMetric",
      "trackDependencyData",
      "startTrackPage",
      "stopTrackPage",
      "startTrackEvent",
      "stopTrackEvent",
      "flush"
    ]
  
    eventTypes.forEach(type => {
      this[type] = props => {
        if (!this.initialized) throw new Error('Application Insights - please initialize the module first');
        this.applicationInsights[type]({...this.defaults,  ...props});
      };      
    });
  }

  initialize = (history, key, defaults) => {
    const instrumentationKey =
      process.env.REACT_APP_CYC_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY || key;

    if (!instrumentationKey || instrumentationKey.length <= 0) {
      console.warn(
        "No application insights key provided, exception logging will not be enabled"
      );
      this.applicationInsights = appInsightsFallback;
      this.initialized = true;
      return;
    }

    this.applicationInsights = new ApplicationInsights({
      config: {
        instrumentationKey,
        maxBatchInterval: 0,
        disableFetchTracking: true,
        extensions: [this.reactPlugin],
        extensionConfig: {
          [this.reactPlugin.identifier]: { history }
        }
      }
    });

    this.applicationInsights.loadAppInsights();
    this.initialized = true;
    this.defaults = defaults;
  };
}

const CycApplicationInsightsReactService = new CycApplicationInsightsReact();

const appInsightsFallback = {
  trackException: ex => console.warn("Application insights is disabled", ex),
  trackEvent: ex => console.warn("Application insights is disabled", ex),
  trackPageView: ex => console.warn("Application insights is disabled", ex),
  trackPageViewPerformance: ex => console.warn("Application insights is disabled", ex),
  trackTrace: ex => console.warn("Application insights is disabled", ex),
  trackMetric: ex => console.warn("Application insights is disabled", ex),
  trackDependencyData: ex => console.warn("Application insights is disabled", ex),
  startTrackPage: ex => console.warn("Application insights is disabled", ex),
  stopTrackPage: ex => console.warn("Application insights is disabled", ex),
  startTrackEvent: ex => console.warn("Application insights is disabled", ex),
  stopTrackEvent: ex => console.warn("Application insights is disabled", ex),
  flush: ex => console.warn("Application insights is disabled", ex)
};

export default CycApplicationInsightsReactService;

export { SeverityLevel };
